<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Orders from "@/services/Orders";
    import modalViewOrder from  '@/components/modals/orders/modalViewOrder';
    import modalEditOrder from  '@/components/modals/orders/modalEditOrder';
    import modalViewTransaction from "@/components/modals/financials/modalViewTransaction";
    import DatePicker from "vue2-datepicker";
    import moment from "moment";

    /**
     * Orders component
     */
    export default {
        components: { Layout, PageHeader, modalViewOrder, modalEditOrder, DatePicker, modalViewTransaction },
        page: {
            title: "Orders",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "Orders",
                items: [
                    {
                        text: "Orders",
                        active: true,
                    },
                ],
                filterInput: {
                    from_date: '',
                    to_date: '',

                },



                isBusy: false,
                guid: '',
                transactionId: '',
                legacy: '',
                order_type: '',
                showDateInfo: true,
                tableData: [],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "created_gmt",
                sortDesc: true,
                fields: [
                    {
                        key: "id",
                        label: "Order ID",
                        class:["text-dark","fw-bold"]
                    },
                    {
                        key: "transaction_id",
                        label: "Transaction Id",
                    },
                    {
                        key: "order_types",
                        label: "Order Type",
                    },
                    {
                        key: "customer_id",
                        label: "Customer",
                    },
                    {
                        key: "total",
                        label: "Total",
                        sortable: true,
                        class:["text-end"]
                    },
                    {
                        key: "paid",
                        label: "Paid",
                        sortable: true,
                    },
                    {
                        key: "currency",
                        label: "Currency",
                    },
                    {
                        key: "source",
                        label: "Source",
                    },
                    {
                        key: "created_gmt",
                        label: "Date/Time",
                        sortable: true,
                    },
                    {
                        key: "status",
                        label: "Order Status",
                        sortable: true,
                    },
                    "action",
                ],
            };
        },

        middleware: "authentication",
        async created() {
            const today = new Date();
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(today.getDate() - 7);
            this.filterInput.from_date = sevenDaysAgo.toISOString().split("T")[0];
            this.filterInput.to_date = today.toISOString().split("T")[0];0
            await this.getOrders()
        },
        methods: {

            async getOrders(){
                try {
                    this.toggleBusy();
                    var filters = this.getFilters()
                    const response = await Orders.getOrders(filters)
                    this.tableData = response.data.data
                    this.totalRows = this.tableData.length
                    this.toggleBusy();
                } catch (error) {
                    this.toggleBusy();
                    this.error = error.response.data.error ? error.response.data.error : "";
                    this.tableData = []
                    this.totalRows =1
                }
            },

           getFilters(){
              var filter_string = '&limit=5000';
              if(this.filterInput.from_date) {
                filter_string += (this.filterInput.from_date) ? '&from_date=' + moment(this.filterInput.from_date).format('YYYY-MM-DD') : "";
              }
              if(this.filterInput.to_date) {
                filter_string += (this.filterInput.to_date) ? '&to_date=' + moment(this.filterInput.to_date).format('YYYY-MM-DD') : "";
              }

              return filter_string = filter_string.substring(1);
            },

            modalViewOrder(data){

                this.$bvModal.show("view_order");
                this.guid = data.guid;
                this.legacy = typeof data.legacy !== 'undefined' ? data.legacy : 0;
                this.order_type = data.order_types;
            },

            modalEditOrder(data){
                this.$bvModal.show("edit_order");
                this.guid = data.guid;
                this.legacy = typeof data.legacy !== 'undefined' ? data.legacy : 0;
                this.order_type = data.order_types;
            },

             modalViewTransaction(transactionId){
                this.$bvModal.show("view_transaction");
                this.transactionId = transactionId;
             },

            /**
             * Search the table data with search input
             */
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },

            hideDateInfo(){
               this.showDateInfo = false;
            }
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">

               <div class="card">
                      <div class="card-body">

                        <div class="row mt-4">
                          <div class="col-md-12">
                            <div class="custom-accordion">
                              <a
                                  class="text-body fw-semibold pb-2 d-block"
                                  data-toggle="collapse"
                                  href="javascript: void(0);"
                                  role="button"
                                  aria-expanded="false"
                                  v-b-toggle.categories-collapse
                              >
                                <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                              </a>
                              <b-collapse visible id="categories-collapse">
                                <div class="card shadow-none">
                                    <div class="row">
                                      <div class="col-sm-12 col-md-3">
                                        <b-form-group label="Start Date" label-for="formrow-from_date-input" class="mb-3">
                                          <date-picker
                                                  v-model="filterInput.from_date"
                                                  append-to-body
                                                  lang="en"
                                                  confirm
                                                  format="DD-MM-YYYY"
                                                  @input="hideDateInfo"
                                          ></date-picker>


                                        </b-form-group>
                                      </div>
                                      <div class="col-sm-12 col-md-3">
                                        <b-form-group label="End Date" label-for="formrow-to_date-input" class="mb-3">
                                          <date-picker
                                                  v-model="filterInput.to_date"
                                                  append-to-body
                                                  lang="en"
                                                  confirm
                                                  format="DD-MM-YYYY"
                                                  @input="hideDateInfo"
                                          ></date-picker>
                                        </b-form-group>
                                      </div>
                                    </div>
                                  <div class="row">
                                    <div class="col d-inline-flex">
                                      <button type="button" class="btn btn-success me-2 w-lg" @click="getOrders">Filter</button>
                                      <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                                    </div>

                                     <p id="date_info" class="text-muted mt-3 mb-0 text-left" v-if="showDateInfo">
                                        <span class=" me-1">
                                          Orders from the last 7 days
                                        </span>
                                     </p>
                                  </div>

                                </div>
                              </b-collapse>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div id="tickets-table_length" class="dataTables_length">
                                <label class="d-inline-flex align-items-center fw-normal">
                                    Show&nbsp;
                                    <b-form-select
                                            v-model="perPage"
                                            size="sm"
                                            :options="pageOptions"
                                    ></b-form-select
                                    >&nbsp;entries
                                </label>
                            </div>
                        </div>
                        <!-- Search -->
                        <div class="col-sm-12 col-md-6">
                            <div
                                    id="tickets-table_filter"
                                    class="dataTables_filter text-md-end"
                            >
                                <label class="d-inline-flex align-items-center fw-normal">
                                    Search:
                                    <b-form-input
                                            v-model="filter"
                                            type="search"
                                            placeholder="Search..."
                                            class="form-control form-control-sm ms-2"
                                    ></b-form-input>
                                </label>
                            </div>
                        </div>
                        <!-- End search -->
                    </div>
                    <!-- Table -->

                    <b-table
                            table-class="table-responsive mb-0"
                            :busy="isBusy"
                            :items="tableData"
                            :fields="fields"
                            responsive="sm"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-included-fields="filterOn"
                            sort-icon-left
                            @filtered="onFiltered"
                            show-empty
                            empty-text="No Data Found"
                    >
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                       <template v-slot:cell(transaction_id)="data">

                              <a
                                  href="javascript:void(0);"
                                  @click="modalViewTransaction(data.item.transaction_id)"
                                  class="px-2 text-primary"
                                  v-b-tooltip.hover
                                  title="View Transaction Details"
                              >
                                {{data.item.transaction_id}}
                              </a>

                        </template>

                         <template v-slot:cell(total)="data">
                            <span>{{parseFloat(data.item.total).toFixed(2)}}</span>
                          </template>

                        <template v-slot:cell(customer_id)="data">
                            <router-link :to="{ path: `/customers/profile/${data.item.customer_id}`}">
                                <span v-if="data.item.first_name || data.item.last_name">{{ data.item.first_name + ' ' +  data.item.last_name }}</span>
                            </router-link>
                        </template>

                        <!--<template v-slot:cell(status)="data">
                            <div
                                    class="badge bg-pill bg-soft-success font-size-12 text-capitalize"
                                    :class="{
                                      'bg-soft-danger': data.item.status === 'cancelled',
                                      'bg-soft-warning': data.item.status === 'processing',
                                      'bg-soft-danger': data.item.status === 'failed',
                                    }"
                            >
                                {{ data.item.status }}
                            </div>
                        </template> -->

                         <template v-slot:cell(status)="data">
                              <div class="font-size-16 text-center" v-html="format_status(data.item.status)"></div>

                        </template>

                        <template v-slot:cell(action)="data">
                            <ul class="list-inline mb-0">
                                <li class="list-inline-item">
                                    <a
                                            href="javascript:void(0);"
                                            @click="modalViewOrder(data.item)"
                                            class="px-2 text-primary"
                                            v-b-tooltip.hover
                                            title="View Order"
                                    >
                                        <i class="uil uil-eye font-size-18"></i>
                                    </a>
                                </li>
                                <li class="list-inline-item">
                                    <a
                                            href="javascript:void(0);"
                                            @click="modalEditOrder(data.item)"
                                            class="px-2 text-primary"
                                            v-b-tooltip.hover
                                            title="Edit Order"
                                    >
                                        <i class="uil uil-pen font-size-18"></i>
                                    </a>
                                </li>
                            </ul>
                        </template>
                    </b-table>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-end">
                            <ul class="pagination pagination-rounded">
                                <!-- pagination -->
                                <b-pagination
                                        v-model="currentPage"
                                        :total-rows="totalRows"
                                        :per-page="perPage"
                                ></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODALS -->
        <modalViewOrder :guid="this.guid" :legacy="this.legacy" :order_type="this.order_type"></modalViewOrder>
        <modalEditOrder :guid="this.guid" :legacy="this.legacy" :order_type="this.order_type"  @onRefresh="getOrders()"></modalEditOrder>
        <modalViewTransaction :transactionId="transactionId"></modalViewTransaction>
        <!-- /MODALS -->

    </Layout>
</template>
